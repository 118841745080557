// extracted by mini-css-extract-plugin
export var wrapper = "DrawingCTA-module--wrapper--lNM1O";
export var isScrollRigEnabled = "DrawingCTA-module--isScrollRigEnabled--Rfydw";
export var hasCustomCursor = "DrawingCTA-module--hasCustomCursor--SAGzh";
export var above = "DrawingCTA-module--above--ej1B9";
export var word = "DrawingCTA-module--word--0MbV2";
export var char = "DrawingCTA-module--char--Cbyii";
export var inView = "DrawingCTA-module--inView--bzTRE";
export var charActive = "DrawingCTA-module--charActive--biaBQ";
export var ctaTitle = "DrawingCTA-module--ctaTitle--9v+My";
export var ctaDescription = "DrawingCTA-module--ctaDescription--3GCgP";
export var descriptionActive = "DrawingCTA-module--descriptionActive--ogPSc";
export var isDrawing = "DrawingCTA-module--isDrawing--H5OPc";
export var drawing = "DrawingCTA-module--drawing--J804y";
export var children = "DrawingCTA-module--children--Id0xv";